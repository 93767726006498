import "./styles.scss";

// type SectionType = ReturnType<typeof import("./about.data").default>["data"]

const About: SectionComponent = ({ section }) => {
  return (
    <section id={section.targetId}>
      <div className="about--content">
        <h2 data-class="text" data-path="about/title">
          {section?.data.title.value}
        </h2>
        <p data-class="text" data-path="about/description">
          {section?.data.description.value}
        </p>
      </div>
      <div className="about--img">
        <img
          data-class="image"
          data-path="about/image"
          src={section?.data.image.src}
          alt={`${section?.data.image.alt || ""}`}
        />
        {section?.data.image.alt ? <span className="image-alt">{section?.data.image.alt}</span> : null}
      </div>
    </section>
  );
};

export default About;
