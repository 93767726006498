import Anchor from "../../components/anchor/anchor";
import Card from "../../components/card/card";
import "./styles.scss";

const Services: SectionComponent = ({ section }) => {
  return (
    <section id={section.targetId}>
      <h2 data-class="text" data-path="services/title">
        {section?.data.title.value}
      </h2>

      <div className="card-group" data-class="group" data-path="services/services">
        {section?.data.services.map((service: WebsitePluginService, index: number) => (
          <Card
            data-class="plugin"
            data-path={`services/services/${index}`}
            data-plugin={"booking/service"}
            index={index}
            key={index}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>

      <Anchor {...section?.data.button} data-class="link" data-path="services/button" size="md" outline>
        {section?.data.button.value}
      </Anchor>
    </section>
  );
};

export default Services;
