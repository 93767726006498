import Home from "./home/home";

const pageComponents: PageComponents = [
  {
    name: "home",
    render: Home,
  },
];

export default pageComponents;
