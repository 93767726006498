import AboutText from "./about.text/about.text";
import AboutVideo from "./about.video/about.video";
import About from "./about/about";
import Categories from "./categories/categories";
import Contact from "./contact/contact";
import Galery from "./galery/galery";
import Introduction from "./introduction/introduction";
import Map from "./map/map";
import Services from "./services/services";
import Team from "./team/team";
/**
 * @description all the sections created inside the theme.
 */
const sectionComponents: SectionComponents = [
  { name: "introduction", render: Introduction },
  { name: "team", render: Team },
  { name: "about", render: About },
  { name: "about_text", render: AboutText },
  { name: "about_video", render: AboutVideo },
  { name: "galery", render: Galery },
  { name: "contact", render: Contact },
  { name: "categories", render: Categories },
  { name: "services", render: Services },
  { name: "map", render: Map },
];

export default sectionComponents;
