import Video from "@soltivo/theme-library/core/components/editables/video/video";
import "./styles.scss";

const AboutVideo: SectionComponent = ({ section }) => {
  const video = section.data.video as WebsiteVideo;

  return (
    <section id={section.targetId}>
      <div className="about--content">
        <h2 data-class="text" data-path="about_video/title">
          {section?.data.title.value}
        </h2>
        <p data-class="text" data-path="about_video/description">
          {section?.data.description.value}
        </p>
      </div>
      <div className="about--video">
        <Video
          {...video}
          options={{
            smFluidBg: true,
          }}
          dataClass="video"
          dataPath="about_video/video"
        />
      </div>
    </section>
  );
};

export default AboutVideo;
