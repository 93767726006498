import { useContext } from "react";
import { WebsiteContext } from "../../data/website.context";
import "./styles.scss";
import Anchor from "../../components/anchor/anchor";

const Contact: SectionComponent = ({ section }) => {
  const { website } = useContext(WebsiteContext);
  return (
    <section id={section.targetId}>
      <div className={`contact-container`}>
        <div className="contact-text">
          <h2 data-class="text" data-path="contact/title">
            {section?.data.title.value}
          </h2>
          <p data-class="text" data-path="contact/description">
            {section?.data.description.value}
          </p>
        </div>
        <div className={`contact--list`}>
          <ul>
            {website.contact.information.telephone && (
              <li>
                <label>{section.data.labels[0]} : </label>
                <span>{website.contact.information.telephone}</span>
              </li>
            )}
            {website.contact.information.email && (
              <li>
                <label>{section.data.labels[1]} : </label>
                <span>{website.contact.information.email}</span>
              </li>
            )}
            {website.contact.information.address && (
              <li>
                <label>{section.data.labels[2]} : </label>
                <span>{website.contact.information.address}</span>
              </li>
            )}
          </ul>

          <Anchor {...section?.data.button} data-class="link" data-path="contact/button" outline>
            {section?.data.button.value}
          </Anchor>
        </div>
      </div>
    </section>
  );
};

export default Contact;
