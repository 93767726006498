import { Logo, useSiteMap } from "@soltivo/theme-library";
import { useEffect, useState } from "react";
import Anchor from "../components/anchor/anchor";
import "./styles.scss";

interface MenuProps {
  website: WebsiteData;
}
const Menu: React.FC<MenuProps> = ({ website }) => {
  // const isBooking = useRouteMatch({
  //     path: "/booking",
  //     exact: true,
  //     sensitive: false,
  // });

  const [menu, setMenu] = useState(false);

  /**
   * @description toggle small device menu for navigation.
   */
  const onToggleMenu = () => setMenu(!menu);

  // if (isBooking) return null;
  const { groups } = useSiteMap({ website });

  const [dropdown, setDropdown] = useState<string>();

  useEffect(() => {
    const onCloseDropdown = () => {
      if (dropdown) {
        const dropdownEl = document.querySelector<HTMLLIElement>(`li>ul[data-dropdown-id="${dropdown}"]`);
        setDropdown("");
        dropdownEl?.parentElement?.blur();
      }
    };

    const onCloseDropdownTarget = (e: Event) => {
      if (e.target instanceof HTMLElement) {
        const dropdownEl = document.querySelector<HTMLUListElement>(`ul[data-dropdown-id="${dropdown}"]`);
        if (dropdownEl && dropdownEl instanceof HTMLElement) {
          if (!dropdownEl.contains(e.target)) {
            onCloseDropdown();
          }
        }
      }
    };

    theme.window?.addEventListener("click", onCloseDropdownTarget);
    theme.window?.addEventListener("wheel", onCloseDropdown);
    return () => {
      theme.window?.removeEventListener("click", onCloseDropdownTarget);
      theme.window?.removeEventListener("wheel", onCloseDropdown);
    };
  }, [theme.window, dropdown]);

  return (
    <header id="header" data-class="menu">
      <div className="container">
        <div className="content">
          <span className="logo">
            <span className="menu" role="button" onClick={onToggleMenu}></span>{" "}
            {website.menu.data.logo?.visible ? (
              <Logo
                logoKey={website.menu.data.logo?.logoKey}
                website={website}
                imgProps={{ style: { maxHeight: "60px", minHeight: "45px" } }}
              />
            ) : null}
          </span>

          {website.menu.data.siteMap?.visible ? (
            <ul className={`${menu ? "nav--open-menu" : ""}`}>
              {groups.map((group) => {
                if (group.items.length === 1) {
                  return (
                    <li key={group.id}>
                      <a onClick={() => setMenu(false)} {...group.items[0]}>
                        {group.items[0].value || ""}
                      </a>
                    </li>
                  );
                }

                return (
                  <li key={group.id} className="group" tabIndex={1}>
                    <span
                      onClick={() => {
                        setDropdown((current) => (current === group.id ? "" : group.id));
                      }}>
                      {group.value} <span className="material-icons">&#58831;</span>
                    </span>
                    <ul data-dropdown-id={group.id} className={dropdown === group.id ? "show" : ""}>
                      {group.items.map((item, gIndex) => {
                        return (
                          <li key={gIndex}>
                            <a
                              onClick={() => {
                                setMenu(false);
                                setDropdown("");
                              }}
                              {...item}>
                              {item.value || ""}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          ) : null}

          {website.menu?.data.links?.visible ? (
            <>
              {website.menu.data.links.items.map((item, index) => (
                <Anchor key={index} className="btn--book-now" outline useOutlineEffect size={"md"} {...item}>
                  {item.value}
                </Anchor>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Menu;
