import Avatar from "../../components/avatar/avatar";
import "./styles.scss";

const Team: SectionComponent = ({ section }) => {
  return (
    <section id="team">
      <h2 data-class="text" data-path="team/title">
        {section?.data.title.value}
      </h2>

      <div className="avatar-group" data-class="group" data-path="team/team">
        {section?.data.team.map((person: { name: string; tag: string; image: string }, index: number) => (
          <Avatar
            data-class="object"
            data-path={`team/team/${index}`}
            key={index}
            name={person.name}
            src={person.image}
            tag={person.tag}
          />
        ))}
      </div>
    </section>
  );
};

export default Team;
