import "./card.styles.scss";

interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string;
  description?: string;
  index?: number;
  image?: {
    src: string;
    alt: string;
  };
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  image,
  // index,
  ...rest
}) => {
  return (
    <div {...rest} className={`card ${image && title && description ? "card-galery" : ""}`}>
      {image && (
        <div className="card--img">
          <img {...image} />
        </div>
      )}

      {title || description ? (
        <div className="card--content">
          {title && <h3>{title}</h3>}
          {description && <p>{description}</p>}
        </div>
      ) : null}
    </div>
  );
};

export default Card;
