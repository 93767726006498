import { Anchor as AnchorTag } from "@soltivo/theme-library";
import { useContext } from "react";
import { WebsiteContext } from "../../data/website.context";

import "./anchor.styles.scss";

interface AnchorProps {
  children: React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
  size?: "md" | "lg";
  border?: boolean;
  padding?: boolean;
  outline?: boolean;
  useOutlineEffect?: boolean;
  className?: string;
}

const Anchor: React.FC<AnchorProps & WebsiteLink> = ({
  outline = false,
  useOutlineEffect = false,
  padding = true,
  border,
  // backgroundColor,
  // textColor = "#ffffff",
  size = "md",
  children,
  className,
  ...rest
}) => {
  const { website } = useContext(WebsiteContext);
  return (
    <AnchorTag
      website={website}
      className={`anchor
        anchor-${size}
        ${padding ? "anchor-padding" : ""}
        ${outline ? "anchor-outline" : ""}
        ${outline && useOutlineEffect ? "anchor-outline-effect" : ""}
        ${border === false ? "anchor-border--none" : ""}
        ${className ? className : ""}`}
      {...rest}>
      {children}
    </AnchorTag>
  );
};

export default Anchor;
