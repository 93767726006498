import "./styles.scss";
import { Map as GoogleMaps } from "@soltivo/theme-library";

const Map: SectionComponent = ({ section }) => {
  return (
    <section id={section.targetId}>
      <div className={`maps-container`}>
        <GoogleMaps
          dataClass="map"
          dataPath="map/map"
          wrapperProps={{
            className: "map",
          }}
          THEME_GOOGLE_API_KEY={process.env.THEME_GOOGLE_API_KEY as string}
          {...(section.data.map as WebsiteMap)}
        />
      </div>
    </section>
  );
};

export default Map;
