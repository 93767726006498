import Routes from "./routes/routes";
import { WebsiteContext } from "./data/website.context";
import { useBuilder } from "@soltivo/theme-library";

const App = ({ global }: { global: WebsiteData }) => {
  const { AppWrapper, website } = useBuilder({
    website: global,
    brain: "display",
    routePath: "/",
  });

  return (
    <AppWrapper website={website}>
      <WebsiteContext.Provider value={{ website: website }}>
        <Routes />
      </WebsiteContext.Provider>
    </AppWrapper>
  );
};

export default App;
