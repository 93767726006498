import "./avatar.styles.scss";
import UserIconSVG from "../icons/UserIcon";
interface AvatarProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  src?: string;
  name?: string;
  tag?: string;
  svgColor?: string;
  rest?: React.HTMLAttributes<HTMLDivElement>;
}

const Avatar: React.FC<AvatarProps> = ({ src, svgColor, name, tag, ...rest }) => {
  return (
    <div className={`avatar`} {...rest}>
      <div
        className="avatar--image"
        style={{
          color: svgColor,
        }}>
        {src ? (
          <img src={src} alt={name} />
        ) : (
          // null
          <UserIconSVG color={svgColor} />
        )}
      </div>
      {(name || tag) && (
        <div className="avatar--info">
          {name && <h4>{name}</h4>}
          {tag && <small>{tag}</small>}
        </div>
      )}
    </div>
  );
};

export default Avatar;
