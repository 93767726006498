import { ThemeRouter } from "@soltivo/theme-library";
import { Fragment, useContext } from "react";
import Footer from "../footer/footer";
import Menu from "../menu/menu";
import { WebsiteContext } from "../data/website.context";
import pageComponents from "../pages";
// import { Redirect, Route } from "react-router-dom";

const Routes = () => {
  const { website } = useContext(WebsiteContext);
  return (
    <Fragment>
      <Menu website={website} />

      <div
        style={{
          marginTop: "100px",
        }}
        className="container">
        <ThemeRouter pageComponents={pageComponents} website={website}>
          {/* Fall back to home page if user tries to access another route. */}
          {/* <Route path={"*"} render={() => <Redirect to={"/"} />} /> */}
        </ThemeRouter>
      </div>
      <Footer website={website} />
    </Fragment>
  );
};

export default Routes;
