import Anchor from "../../components/anchor/anchor";
import "./styles.scss";

const Introduction: SectionComponent = ({ section }) => {
  return (
    <section id={section.targetId}>
      <div
        className="background"
        data-class="background-image"
        data-path="introduction/background"
        style={{
          backgroundImage: `url(${section?.data.background.src})`,
        }}>
        <div className="intro--container">
          <h1 data-class="text" data-path="introduction/title">
            {section?.data.title.value}
          </h1>
          <p data-class="text" data-path="introduction/description">
            {section?.data.description.value}{" "}
          </p>
          <Anchor
            data-class="link"
            data-path="introduction/link"
            className="btn--book-now"
            size="lg"
            {...section.data.link}>
            {section?.data.link.value}
          </Anchor>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
