import "./styles.scss";
import { useRouteMatch } from "react-router-dom";
import { Logo, useSiteMap } from "@soltivo/theme-library";
import Anchor from "../components/anchor/anchor";

interface FooterProps {
  website: WebsiteData;
}

const Footer: React.FC<FooterProps> = ({ website }) => {
  const isBooking = useRouteMatch({
    path: "/booking",
    exact: true,
    sensitive: false,
  });

  const { flatItems } = useSiteMap({ website });

  if (isBooking?.isExact) return null;

  return (
    <div id="footer" data-class="footer">
      <div className="container">
        {/* --------------- */}

        <div className="header">
          {website.footer.data.logo?.visible ? (
            <Logo
              spanProps={{
                style: {
                  fontSize: 18,
                  fontWeight: 500,
                  color: "var(--accent)",
                },
              }}
              imgProps={{ style: { maxHeight: "60px", minHeight: "45px" } }}
              logoKey={website.footer.data.logo?.logoKey}
              website={website}
            />
          ) : null}

          {website.footer.data.socialMedia?.visible ? (
            <span className="social-medias">
              {website?.contact.socialMedia.map((sm) => {
                return (
                  <a
                    key={sm.id}
                    href={sm.href}
                    target="_blank"
                    rel="noreferrer"
                    title={`${sm.name}`}
                    dangerouslySetInnerHTML={{ __html: sm.svgHTML }}
                  />
                );
              })}
            </span>
          ) : null}
        </div>

        <div className="content">
          {website.footer.data.contactInformation?.visible ? (
            <div className="column">
              <span>{website.footer.data.contactInformation.title}</span>
              <ul>
                {Object.keys(website.contact.information).map((key) => {
                  return <li key={key}>{website.contact.information[key]}</li>;
                })}
              </ul>
            </div>
          ) : null}

          {website.footer.data.siteMap?.visible ? (
            <div className="column">
              <span>{website.footer.data.siteMap.title}</span>
              <ul
                style={{
                  gridTemplateColumns: `repeat(${Math.ceil(flatItems.length / 5)}, 1fr)`,
                  gridTemplateRows: `repeat(5, 1fr)`,
                }}>
                {flatItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <a {...item}>{item.value || ""}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}

          {website.footer.data.links?.visible ? (
            <div className="column">
              <span>{website.footer.data.links.title}</span>

              <ul>
                {website.footer.data.links.items.map((item, index) => {
                  return (
                    <li key={index}>
                      <Anchor className="btn--book-now" size="lg" {...item}>
                        {item.value}
                      </Anchor>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}

          {website.footer.data.text?.visible ? (
            <div className="column">
              <span>{website.footer.data.text.title}</span>

              <p>{website.footer.data.text.value}</p>
            </div>
          ) : null}
        </div>

        {/* --------------- */}
        <div className="footer">
          {website?.footer.data.copyright ? (
            <span className="copyright">{`${website?.footer.data.copyright.value}`}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Footer;
