import Card from "../../components/card/card";
import "./styles.scss";

const Galery: SectionComponent = ({ section }) => {
  return (
    <section id={section.targetId}>
      <h2 data-class="text" data-path="galery/title">
        {section?.data.title.value}
      </h2>
      <p data-class="text" data-path="galery/description">
        {section?.data.description.value}
      </p>

      <div className="card-group" data-class="group" data-path="galery/photos">
        {section?.data.photos.map(
          (
            photo: {
              id: string;
              image: string;
              title?: string;
              description?: string;
            },
            index: number
          ) => (
            // <div className="">
            //   <div data-class="object" data-path="test"></div>
            // </div>
            <Card
              key={index}
              index={index}
              data-class="object"
              // data-path={`galery/photos/${index}`}
              // // or
              data-path={`galery/photos/id:${photo.id}`}
              image={{
                alt: photo.title || "image",
                src: photo.image,
              }}
              title={photo.title}
              description={photo.description}
            />
          )
        )}
      </div>
    </section>
  );
};

export default Galery;
