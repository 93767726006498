import "./styles.scss";

const AboutText: SectionComponent = ({ section }) => {
  return (
    <section id={section.targetId}>
      <div className="about--content">
        <h2 data-class="text" data-path="about_text/title">
          {section?.data.title.value}
        </h2>
        <p data-class="text" data-path="about_text/description">
          {section?.data.description.value}
        </p>
      </div>
    </section>
  );
};

export default AboutText;
